<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card :header="'Gebruiker - ' + title" class="mt-4">
          <div v-if="saving">
            <b-spinner /> Opslaan...
          </div>

          <b-form v-if="form.values != null" v-show="!saving" @submit.prevent="onSubmit" autocomplete="off">
            <form-field 
              v-for="(field, name) in form.fields" 
              :field="field" 
              :key="name" 
              :form="form" 
              :name="name" 
              :errors="Object.keys(errors).includes(name) ? errors[name] : []"
            /> 

            <b-button type="submit" variant="primary">Opslaan</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import FormField from '@/components/FormField'

  export default {
    name: "UserDetailEdit",
    components: {
      FormField,
    },
    data() {
      return {
        title: '',
        saving: false,
        errors: {},
        form: {
          values: null,
          validated: false,
          fields: {
            "Username": {
              label: "Username",
              required: true,
              type: "text",
              regex: [
                '^[0-9a-z_.-]+$',
                'Gebruikersnaam mag alleen bestaan uit kleine letters, getallen, punt, streepje en laag streepje'
              ]
            },
            "ContactName": {
              label: "Naam",
              type: "text"
            },
            "Email": {
              label: "E-mail",
              required: true,
              type: "email"
            },
            "ContractID": {
              label: "Contract",
              type: "readonly",
            },
            "Status": {
              label: "Status",
              type: "select",
              required: true,
              options: [
                { value: 'active', text: 'Actief' },
                { value: 'inactive', text: 'Inactief' },
                { value: 'deleted', text: 'Verwijderd' },
                { value: 'new', text: 'Nieuw' },
              ]
            },
            "AllowAnalytics": {
              label: "Allow Analytics",
              type: "checkbox",
              required: true,
            },
            "AllowTrends": {
              label: "Allow Trends",
              type: "checkbox",
              required: true,
            },
            "AllowRoyalty": {
              label: "Allow Royalties",
              type: "checkbox",
              required: true,
            },
            "AllowManageReleaseConcepts": {
              label: "Allow Manage Release Concepts",
              type: "checkbox",
              required: true,
            },
          }
        }  
        
      };
    },
    props: {
      user: Object,
    },
    methods: {
      async onSubmit () {
        let errors = {}
        for (const [name, data] of Object.entries(this.form.fields)) {
          if (!data.regex) continue
          const [regex, errorMessage] = data.regex
          if (!this.form.values[name].match(regex)) {
            this.$set(errors, 'Username', [errorMessage])
          }
        }

        if (Object.keys(errors).length > 0) {
          this.errors = errors
          return
        }
        
        this.saving = true
        let response

        try {
          if (this.user) {
            response = await this.$http.put('users/'+this.user.UserID, this.form.values)
          } else {
            response = await this.$http.post('users', this.form.values)
          }

          if (response.data.error) {
            if (response.data.error == 'user_already_exists') {
              this.$notify({
                type: 'danger',
                text: 'Deze gebruikersnaam is al in gebruik'
              });
              this.saving = false
              return
            }

            throw Error(response.data.error)
          }

          this.$emit('refresh')
          this.$router.push({ name: 'user-detail', params: { id: response.data.UserID } })

        } catch(error) {
          console.log(error)
          this.$notify({
            type: 'danger',
            text: 'Er ging iets mis bij het laden'
          });
        }
      }
    },
    watch: {
    },
    mounted() {
      if (this.user == null) {
        this.title = 'Nieuw'
        this.$set(this.form, 'values', {
          ContractID: this.$route.params.ContractID,
        })
      } else {
        this.title = this.user.Username
        this.$set(this.form, 'values', {...this.user})
      }

      this.$root.$data.crumbs = [
        { to: { name: "user-search" }, text: "Gebruikers" },
        { text: this.title },
      ];
    }
  };
</script>

<style lang="scss" scoped>
.track {
  .card-header {
    color: black;
    background-color: #acbcc8;
  }
}
</style>